var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { getBettermodeCommunityData, getBettermodeExtraMemberData } from "./bettermodeCommunityData";
import { abortThenGetNewController, isStaffMember, getSupporterId } from "./bettermodeCommunityHelpers";
import { dataLayerPush, normalizeObject, getWVSubdomain, asCallback } from "../shared/utils";
import { initializeVideoTracking } from "./bettermodeCommunityVideo";
var linkEventController;
var menuBoxEventController;
var tabPanelEventController;
globalThis.bettermodeCommunityDataCache = {};
export var parsePageType = function (pathname) {
    var pageTypes = [
        {
            pattern: /^\/manage\/(.+)$/,
            pageType: 'manage',
            hasSpace: false,
            track: false
        },
        {
            pattern: /^\/collection(\/(.+))?$/,
            pageType: 'collection',
            systemSpace: true,
            systemSlug: 'default::collection',
            hasSpace: false,
            track: false
        },
        {
            pattern: /^\/notifications(\/(.*))?$/,
            pageType: 'notifications',
            systemSpace: true,
            systemSlug: 'default::notifications',
            hasSpace: false,
            track: false
        },
        {
            pattern: /^\/auth\/(.+)$/,
            pageType: 'auth',
            systemSpace: true,
            systemSlug: 'default::auth',
            hasSpace: false,
            track: false
        },
        {
            pattern: /\/post\/.+-([^\-]+)\/new$/,
            parseId: true,
            pageType: 'new-post',
            hasSpace: false,
            track: false
        },
        {
            pattern: /\/post\/.+-([^\-]+)\/edit$/,
            parseId: true,
            pageType: 'edit-post',
            hasSpace: false,
            track: false
        },
        {
            pattern: /^.+\/new\/(.*)$/,
            pageType: 'new',
            hasSpace: true,
            track: true
        },
        {
            pattern: /^.+\/new\/\?post_type=([^=#&]+)$/,
            pageType: 'new',
            hasSpace: true,
            track: true,
            postTypeForm: true
        },
        {
            pattern: /^\/settings\/moderation(.*)$/,
            pageType: 'moderation',
            systemSpace: true,
            systemSlug: 'default::moderation',
            hasSpace: true,
            track: false
        },
        {
            pattern: /^\/settings\/(.+)$/,
            pageType: 'settings',
            systemSpace: true,
            systemSlug: 'default::settings',
            hasSpace: true,
            track: false
        },
        {
            pattern: /^\/members\/(.+)\/settings$/,
            pageType: 'members',
            systemSpace: false,
            hasSpace: true,
            track: false
        },
        {
            pattern: /^\/member\/(.+)$/,
            pageType: 'member',
            systemSpace: true,
            systemSlug: 'default::member',
            hasSpace: true,
            track: false
        },
        {
            pattern: /^\/404$/,
            pageType: '404',
            systemSpace: true,
            hasSpace: false,
            track: false
        },
        {
            pattern: /^\/search\/(.*)$/,
            pageType: 'search',
            systemSpace: true,
            systemSlug: 'default::search',
            hasSpace: true,
            track: true
        },
        {
            pattern: /^\/messaging\/(.+)$/,
            pageType: 'messaging',
            systemSpace: false,
            hasSpace: false,
            track: true
        },
        {
            pattern: /^.+\/post\/.+-([^\-]+)(?:\?.*)?$/,
            parseId: true,
            pageType: 'post',
            hasSpace: true,
            track: true
        }
    ];
    var defaultPageType = {
        key: "default",
        pattern: /^\/([^\/]+)\/?.*$/,
        pageType: 'space',
        hasSpace: true,
        track: true
    };
    for (var _i = 0, pageTypes_1 = pageTypes; _i < pageTypes_1.length; _i++) {
        var pageTypeItem = pageTypes_1[_i];
        var pattern = pageTypeItem.pattern, parseId = pageTypeItem.parseId, postTypeForm = pageTypeItem.postTypeForm;
        var match = pattern.exec(pathname);
        var postId = match && match[1] && !postTypeForm ? match[1] : null;
        var postTypeId = postTypeForm && match && match[1] ? match[1] : null;
        if (match) {
            var pattern_1 = pageTypeItem.pattern, pageTypeData = __rest(pageTypeItem, ["pattern"]);
            return __assign({ postId: parseId ? postId : null, newPostTypeId: postTypeId }, pageTypeData);
        }
    }
    if (defaultPageType.pattern.test(pathname)) {
        var pattern = defaultPageType.pattern, pageTypeData = __rest(defaultPageType, ["pattern"]);
        return __assign({ postId: null }, pageTypeData);
    }
    else {
        return {
            postId: null,
            pageType: 'unknown',
            hasSpace: false,
            track: false
        };
    }
};
export var isTrackablePage = function (pageTypeData) {
    var nonTrackablePages = ['manage', 'edit-post', 'moderation'];
    return pageTypeData.track === false || !nonTrackablePages.includes(pageTypeData.pageType);
};
export var getExtraMemberData = function (currentMember) { return __awaiter(void 0, void 0, void 0, function () {
    var memberId, extraMemberData;
    var _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                memberId = (currentMember || {}).id;
                if (globalThis.extraMemberData && ((_a = Object.keys(globalThis.extraMemberData)) === null || _a === void 0 ? void 0 : _a.length)) {
                    return [2 /*return*/, globalThis.extraMemberData];
                }
                return [4 /*yield*/, getBettermodeExtraMemberData(memberId)];
            case 1:
                extraMemberData = _b.sent();
                if (!extraMemberData) {
                    return [2 /*return*/, {}];
                }
                globalThis.extraMemberData = extraMemberData;
                return [2 /*return*/, extraMemberData];
        }
    });
}); };
export var bettermodePageViewEventHandler = function (e) { return __awaiter(void 0, void 0, void 0, function () {
    var eventData, currentMember, isWvStaff, pageTypeData, isTrackable, memberExtraData, bettermodeData, currentSpaceRaw, currentSystemSpace, currentPost, currentPage, pageEntity, isPostPage, user_role, post_or_space, post_id, _a, _b, postType, _c, postSlug, _d, postTitle, _e, postTypeName, _f, postTypeId, space_or_BM_cms_model, MB_cms_model, currentSpace, subdomain, supporterId, spaceId, spaceName, spaceSlug, pageTitle, pageName;
    var _g, _h, _j;
    return __generator(this, function (_k) {
        switch (_k.label) {
            case 0:
                eventData = e.detail || {};
                currentMember = globalThis.currentMemberData || null;
                isWvStaff = isStaffMember(currentMember || {});
                pageTypeData = parsePageType(window.location.pathname);
                isTrackable = isTrackablePage(pageTypeData);
                return [4 /*yield*/, getExtraMemberData(currentMember)];
            case 1:
                memberExtraData = _k.sent();
                if (!isTrackable) {
                    return [2 /*return*/];
                }
                safeExecute(function () { return initializeVideoTracking(); });
                return [4 /*yield*/, getBettermodeCommunityData(pageTypeData)];
            case 2:
                bettermodeData = _k.sent();
                if (!bettermodeData || !currentMember) {
                    return [2 /*return*/];
                }
                safeExecute(function () { return setupBettermodeLinkTracking(); });
                currentSpaceRaw = bettermodeData.currentSpace, currentSystemSpace = bettermodeData.currentSystemSpace, currentPost = bettermodeData.currentPost, currentPage = bettermodeData.currentPage;
                pageEntity = (currentPage === null || currentPage === void 0 ? void 0 : currentPage.entity) || null;
                isPostPage = (pageEntity === null || pageEntity === void 0 ? void 0 : pageEntity.__typename) === 'Post';
                user_role = isWvStaff ? "staff" : "member";
                post_or_space = pageTypeData.pageType === "post" || isPostPage ? "post" : "space";
                post_id = isPostPage && pageEntity.id || pageTypeData.postId;
                _a = currentPost || {}, _b = _a.postType, postType = _b === void 0 ? {} : _b, _c = _a.slug, postSlug = _c === void 0 ? "" : _c, _d = _a.title, postTitle = _d === void 0 ? "" : _d;
                _e = postType.name, postTypeName = _e === void 0 ? "" : _e, _f = postType.id, postTypeId = _f === void 0 ? "" : _f;
                space_or_BM_cms_model = postTypeName || "space";
                MB_cms_model = space_or_BM_cms_model;
                currentSpace = currentSpaceRaw || currentSystemSpace;
                subdomain = getWVSubdomain();
                supporterId = getSupporterId(memberExtraData);
                spaceId = (currentSpace === null || currentSpace === void 0 ? void 0 : currentSpace.id) || ((_g = currentPage === null || currentPage === void 0 ? void 0 : currentPage.space) === null || _g === void 0 ? void 0 : _g.id) || null;
                spaceName = (currentSpace === null || currentSpace === void 0 ? void 0 : currentSpace.name) || ((_h = currentPage === null || currentPage === void 0 ? void 0 : currentPage.space) === null || _h === void 0 ? void 0 : _h.name) || null;
                spaceSlug = (currentSpace === null || currentSpace === void 0 ? void 0 : currentSpace.slug) || ((_j = currentPage === null || currentPage === void 0 ? void 0 : currentPage.space) === null || _j === void 0 ? void 0 : _j.slug) || null;
                pageTitle = window.document.title;
                pageName = [spaceId, pageTitle, post_id].filter(function (val) { return val !== null; }).join(':');
                globalThis.dataLayer = globalThis.dataLayer ? globalThis.dataLayer : [];
                if (globalThis.dataLayer.some(function (_a) {
                    var event = _a.event;
                    return event && event === "page_view";
                })) {
                    /*
                    * If the dataLayer already has a page_view event, then we need to flush the dataLayer
                    */
                    dataLayerPush({ event: "flush_datalayer" });
                }
                dataLayerPush(normalizeObject({
                    event: "page_view",
                    page_category: "wv_community:".concat(spaceId, ":").concat(spaceName),
                    page_display_type: "pv",
                    page_journey_phase: 'cultivation',
                    page_name: pageName,
                    page_path: window.location.pathname,
                    page_reporting_category: "wv_community",
                    page_subcategory: post_or_space,
                    page_template: space_or_BM_cms_model,
                    page_title: pageTitle,
                    page_type: MB_cms_model,
                    previous_page_name: "required",
                    site_language: window.navigator.language,
                    site_name: "bettermode",
                    site_type: subdomain,
                    site_web_property: "community",
                    supporter_id: supporterId,
                    user_logged_in: "logged_in",
                    user_role: user_role,
                    bm_member_id: currentMember.id || undefined,
                    user_email: currentMember.email || undefined,
                    bm_space_id: spaceId || undefined,
                    bm_space_name: spaceName || undefined,
                    bm_space_slug: spaceSlug || undefined,
                    bm_post_type: postTypeName || undefined,
                    bm_post_type_id: postTypeId || undefined,
                    bm_post_slug: postSlug || undefined,
                    bm_post_name: postTitle || undefined,
                }));
                return [2 /*return*/];
        }
    });
}); };
export var bettermodeElementClickEventHandler = function (e) {
    var eventDataContext = e.detail || {};
    var event_action = eventDataContext.event_action, _a = eventDataContext.clickType, clickType = _a === void 0 ? "text" : _a, _b = eventDataContext.eventName, eventName = _b === void 0 ? "outbound_link" : _b, click_text = eventDataContext.click_text, blockId = eventDataContext.blockId, blockName = eventDataContext.blockName, elementId = eventDataContext.elementId, _c = eventDataContext.ariaLabel, ariaLabel = _c === void 0 ? "" : _c, destination_url = eventDataContext.destination_url;
    var click_id = (blockId && blockName) ? "".concat(blockId, ":").concat(blockName).concat(ariaLabel ? ":".concat(ariaLabel) : '') : elementId;
    dataLayerPush(normalizeObject({
        event: eventName,
        event_action: event_action || "".concat(clickType, "_click"),
        event_category: eventName,
        event_label: "".concat(clickType, ":").concat(destination_url),
        click_text: click_text,
        click_link: destination_url,
        click_id: click_id,
    }));
};
// Generated by ChatGPT
function hasParentOfType(element, tagNames) {
    if (typeof tagNames === "string") {
        tagNames = [tagNames];
    }
    tagNames = tagNames.map(function (tagName) { return tagName.toUpperCase(); });
    while (element) {
        if (tagNames.includes(element.tagName)) {
            return true;
        }
        element = element.parentElement;
    }
    return false;
}
export var triggerElementClickEvent = function (eventData) {
    var event = new CustomEvent('bettermode_element_click', { detail: eventData });
    window.document.dispatchEvent(event);
};
export var safeExecute = function (callback) {
    try {
        callback();
    }
    catch (err) {
        console.error(err);
    }
};
export var setupBettermodeLinkTracking = function () {
    linkEventController = abortThenGetNewController(linkEventController);
    menuBoxEventController = abortThenGetNewController(menuBoxEventController);
    setMainBlockElements(linkEventController.signal);
    setSideMenuBlockElements(linkEventController.signal);
    setMainNavigationElements(linkEventController.signal);
};
export var outBoundOrInBoundLink = function (linkUrl) {
    if (linkUrl === void 0) { linkUrl = null; }
    var url = linkUrl || window.location.href;
    return url.includes(".worldvision.org") ? "internal_link" : "outbound_link";
};
export var getAriaLabel = function (element) {
    return (element === null || element === void 0 ? void 0 : element.getAttribute("aria-label")) || "";
};
export var checkImageLink = function (element) {
    if ((element === null || element === void 0 ? void 0 : element.matches('img')) ||
        !!(element === null || element === void 0 ? void 0 : element.querySelector('img')) ||
        (element === null || element === void 0 ? void 0 : element.matches('[style*="background-image"]')) ||
        !!(element === null || element === void 0 ? void 0 : element.querySelector('[style*="background-image"]'))) {
        return "image";
    }
    else if (findMenuIcon(element)) {
        return "icon";
    }
    else {
        return false;
    }
};
export var findMenuIcon = function (menuItem) {
    var menuIcon = menuItem.querySelector('svg use[href*="#"]');
    if (!menuIcon) {
        return null;
    }
    var results = /#(.*)$/.exec(menuIcon.href);
    return results && results[1];
};
export var setMainBlockElements = function (signal) {
    var blocks = window.document.querySelectorAll('main [data-block-name]');
    if (!blocks || !blocks.length) {
        return;
    }
    blocks && blocks.forEach(function (block) {
        var blockId = block === null || block === void 0 ? void 0 : block.dataset["blockId"];
        var blockName = block === null || block === void 0 ? void 0 : block.dataset["blockName"];
        if (blockName === "tabs") {
            return setupTabPanelElements(block, blockName, blockId);
        }
        var links = block.querySelectorAll('a');
        links.length && links.forEach(function (link) {
            link.addEventListener('click', function (event) {
                var _a;
                var ariaLabel = getAriaLabel(event.target);
                var clickText = ((_a = event.target) === null || _a === void 0 ? void 0 : _a.innerText) || ariaLabel;
                var isImageOrIcon = !clickText && checkImageLink(event.target);
                if (isImageOrIcon) {
                    clickText = isImageOrIcon;
                }
                triggerElementClickEvent({
                    blockId: blockId,
                    blockName: blockName,
                    clickType: isImageOrIcon ? "image" : "text",
                    eventName: outBoundOrInBoundLink(link.href),
                    elementId: link.id,
                    click_text: clickText,
                    destination_url: link.href || window.location.href,
                });
            }, { signal: signal });
        });
        var buttons = block.querySelectorAll('button');
        buttons.length && buttons.forEach(function (button) {
            button.addEventListener('click', asCallback(function (event) {
                var ariaLabel = getAriaLabel(button);
                var isMenuPopUpTrigger = event.target.matches('[aria-haspopup="menu"][aria-expanded="true"]');
                var isClosestMenuTrigger = !!event.target.closest('[aria-haspopup="menu"][aria-expanded="true"]');
                var clickType = "button";
                if (isMenuPopUpTrigger || isClosestMenuTrigger) {
                    setupMenuBoxElements(blockName, blockId, "profile");
                }
                if (button.matches('[role="tab"]')) {
                    clickType = "tab";
                }
                else if (blockName === "accordion" && button.matches('[aria-expanded]')) {
                    clickType = button.matches('[aria-expanded="false"]') ? "collapse" : "expand";
                }
                triggerElementClickEvent({
                    blockId: blockId,
                    blockName: blockName,
                    clickType: clickType,
                    eventName: outBoundOrInBoundLink(),
                    elementId: button.id,
                    click_text: button.innerText || ariaLabel,
                    destination_url: window.location.href,
                    ariaLabel: ariaLabel
                });
            }), { signal: signal });
        });
        var cards = block.querySelectorAll('.shadow-card.cursor-pointer');
        cards.length && cards.forEach(function (card) {
            var containedLink = card.querySelector('a[href]') || {};
            card.addEventListener('click', function (event) {
                var _a, _b;
                if (hasParentOfType(event.target, ['button', 'a'])) {
                    return;
                }
                var ariaLabel = getAriaLabel(event.target);
                var clickText = ((_a = event.target) === null || _a === void 0 ? void 0 : _a.innerText) || ariaLabel;
                var isImageOrIcon = !clickText && checkImageLink(event.target);
                if (isImageOrIcon) {
                    clickText = isImageOrIcon;
                }
                triggerElementClickEvent({
                    blockId: blockId,
                    blockName: blockName,
                    clickType: "card",
                    eventName: outBoundOrInBoundLink(containedLink.href),
                    elementId: (_b = event.target) === null || _b === void 0 ? void 0 : _b.id,
                    click_text: clickText,
                    destination_url: (containedLink === null || containedLink === void 0 ? void 0 : containedLink.href) || window.location.href,
                    ariaLabel: ariaLabel
                });
            }, { signal: signal });
        });
        var images = block.querySelectorAll('img.cursor-pointer');
        images.length && images.forEach(function (image) {
            image.addEventListener('click', function (event) {
                var _a;
                var ariaLabel = getAriaLabel(image);
                var clickText = (image === null || image === void 0 ? void 0 : image.alt) || ariaLabel || "image";
                setupMenuBoxElements(blockName, blockId);
                triggerElementClickEvent({
                    blockId: blockId,
                    blockName: blockName,
                    clickType: "image",
                    eventName: outBoundOrInBoundLink(),
                    elementId: (_a = event.target) === null || _a === void 0 ? void 0 : _a.id,
                    click_text: clickText,
                    destination_url: window.location.href,
                    ariaLabel: ariaLabel
                });
            }, { signal: signal });
        });
        var figures = block.querySelectorAll('figure [data-slide-index].cursor-pointer > div');
        figures.length && figures.forEach(function (figure) {
            var includesButtonsLinks = !!figure.querySelector('button') || !!figure.querySelector('a[href]');
            if (includesButtonsLinks) {
                return;
            }
            figure.addEventListener('click', function (event) {
                var _a, _b;
                var ariaLabel = getAriaLabel(event.target);
                var clickText = ((_a = event.target) === null || _a === void 0 ? void 0 : _a.innerText) || ariaLabel;
                var isImageOrIcon = !clickText && checkImageLink(event.target);
                if (isImageOrIcon) {
                    clickText = isImageOrIcon;
                }
                triggerElementClickEvent({
                    blockId: blockId,
                    blockName: blockName,
                    clickType: "figure",
                    eventName: outBoundOrInBoundLink(),
                    elementId: (_b = event.target) === null || _b === void 0 ? void 0 : _b.id,
                    click_text: clickText,
                    destination_url: window.location.href,
                    ariaLabel: ariaLabel
                });
            }, { signal: signal });
        });
    });
};
export var setSideMenuBlockElements = function (signal) {
    var navBlocks = window.document.querySelectorAll('aside[aria-label="Navigation"] nav[data-block-name]');
    if (!navBlocks || !navBlocks.length) {
        return;
    }
    navBlocks && navBlocks.forEach(function (navBlock) {
        var blockId = navBlock === null || navBlock === void 0 ? void 0 : navBlock.dataset["blockId"];
        var blockName = navBlock === null || navBlock === void 0 ? void 0 : navBlock.dataset["blockName"];
        var links = navBlock.querySelectorAll('a');
        links.length && links.forEach(function (link) {
            link.addEventListener('click', function () {
                triggerElementClickEvent({
                    blockId: blockId,
                    blockName: blockName,
                    clickType: "menu",
                    eventName: outBoundOrInBoundLink(link.href),
                    elementId: link.id,
                    click_text: link.innerText,
                    destination_url: link.href,
                });
            }, { signal: signal });
        });
    });
};
export var setMainNavigationElements = function (signal) {
    var navBlock = window.document.querySelector('header [data-block-name="navbar"]');
    if (!navBlock) {
        return;
    }
    var blockId = navBlock === null || navBlock === void 0 ? void 0 : navBlock.dataset["blockId"];
    var blockName = navBlock === null || navBlock === void 0 ? void 0 : navBlock.dataset["blockName"];
    var logoLink = navBlock.querySelector('a.logo');
    logoLink && (logoLink === null || logoLink === void 0 ? void 0 : logoLink.addEventListener('click', function () {
        triggerElementClickEvent({
            blockId: blockId,
            blockName: blockName,
            clickType: "image",
            eventName: outBoundOrInBoundLink(logoLink === null || logoLink === void 0 ? void 0 : logoLink.href),
            elementId: logoLink.id,
            click_text: "logo",
            destination_url: logoLink.href,
        });
    }, { signal: signal }));
    var newPostLink = navBlock.querySelector('a[href="/new"]');
    newPostLink && (newPostLink === null || newPostLink === void 0 ? void 0 : newPostLink.addEventListener('click', function () {
        var ariaLabel = getAriaLabel(newPostLink);
        triggerElementClickEvent({
            blockId: blockId,
            blockName: blockName,
            clickType: "menu",
            eventName: outBoundOrInBoundLink(newPostLink === null || newPostLink === void 0 ? void 0 : newPostLink.href),
            elementId: newPostLink.id,
            click_text: newPostLink.innerText || ariaLabel,
            destination_url: newPostLink.href,
            ariaLabel: ariaLabel
        });
    }, { signal: signal }));
    var buttons = navBlock.querySelectorAll('button');
    buttons.length && buttons.forEach(function (button) {
        button.addEventListener('click', asCallback(function (event) {
            var isMenuPopUpTrigger = event.target.matches('[aria-haspopup="menu"][aria-expanded="true"]');
            var isClosestMenuTrigger = !!event.target.closest('[aria-haspopup="menu"][aria-expanded="true"]');
            if (isMenuPopUpTrigger || isClosestMenuTrigger) {
                setupMenuBoxElements(blockName, blockId, "profile");
            }
            var ariaLabel = getAriaLabel(button);
            triggerElementClickEvent({
                blockId: blockId,
                blockName: blockName,
                clickType: "menu",
                eventName: outBoundOrInBoundLink(),
                elementId: button.id,
                click_text: button.innerText || ariaLabel,
                destination_url: window.location.href,
                ariaLabel: ariaLabel
            });
        }), { signal: signal });
    });
};
export var setupMenuBoxElements = function (blockName, blockId, clickType) {
    if (clickType === void 0) { clickType = "text"; }
    menuBoxEventController = abortThenGetNewController(menuBoxEventController);
    var menuBlock = window.document.getElementById('headlessui-portal-root');
    var menuLinks = menuBlock.querySelectorAll('a');
    menuLinks && menuLinks.length && menuLinks.forEach(function (menuLink) {
        menuLink.addEventListener('click', function () {
            var ariaLabel = getAriaLabel(menuLink);
            triggerElementClickEvent({
                blockId: blockId,
                blockName: blockName,
                clickType: clickType,
                eventName: outBoundOrInBoundLink(menuLink === null || menuLink === void 0 ? void 0 : menuLink.href),
                elementId: menuLink.id,
                click_text: menuLink.innerText || ariaLabel,
                destination_url: menuLink.href,
                ariaLabel: ariaLabel
            });
        }, { signal: menuBoxEventController.signal });
    });
    var menuButtons = menuBlock.querySelectorAll('button');
    menuButtons && menuButtons.length && menuButtons.forEach(function (menuButton) {
        menuButton.addEventListener('click', function () {
            var ariaLabel = getAriaLabel(menuButton);
            triggerElementClickEvent({
                blockId: blockId,
                blockName: blockName,
                clickType: clickType,
                eventName: outBoundOrInBoundLink(),
                elementId: menuButton.id,
                click_text: menuButton.innerText || ariaLabel,
                destination_url: window.location.href,
                ariaLabel: ariaLabel
            });
        }, { signal: menuBoxEventController.signal });
    });
};
export var setupTabPanelElements = function (block, blockName, blockId) {
    tabPanelEventController = abortThenGetNewController(tabPanelEventController);
    var signal = tabPanelEventController.signal;
    var links = block.querySelectorAll('a');
    links.length && links.forEach(function (link) {
        link.addEventListener('click', function (event) {
            var _a;
            var ariaLabel = getAriaLabel(event.target);
            var clickText = ((_a = event.target) === null || _a === void 0 ? void 0 : _a.innerText) || ariaLabel;
            var isImageOrIcon = !clickText && checkImageLink(event.target);
            if (isImageOrIcon) {
                clickText = isImageOrIcon;
            }
            triggerElementClickEvent({
                blockId: blockId,
                blockName: blockName,
                clickType: isImageOrIcon ? "image" : "text",
                eventName: outBoundOrInBoundLink(link.href),
                elementId: link.id,
                click_text: clickText,
                destination_url: link.href || window.location.href,
            });
        }, { signal: signal });
    });
    var buttons = block.querySelectorAll('button');
    buttons.length && buttons.forEach(function (button) {
        button.addEventListener('click', asCallback(function (event) {
            var ariaLabel = getAriaLabel(button);
            var isMenuPopUpTrigger = event.target.matches('[aria-haspopup="menu"][aria-expanded="true"]');
            var isClosestMenuTrigger = !!event.target.closest('[aria-haspopup="menu"][aria-expanded="true"]');
            var clickType = "button";
            if (isMenuPopUpTrigger || isClosestMenuTrigger) {
                setupMenuBoxElements(blockName, blockId, "profile");
            }
            if (button.matches('[role="tab"]')) {
                clickType = "tab";
            }
            else if (blockName === "accordion" && button.matches('[aria-expanded]')) {
                clickType = button.matches('[aria-expanded="false"]') ? "collapse" : "expand";
            }
            triggerElementClickEvent({
                blockId: blockId,
                blockName: blockName,
                clickType: clickType,
                eventName: outBoundOrInBoundLink(),
                elementId: button.id,
                click_text: button.innerText || ariaLabel,
                destination_url: window.location.href,
                ariaLabel: ariaLabel
            });
            if (clickType === "tab") {
                // The Area Gets Re-rendered - It's time to re-attach the event listeners
                setupTabPanelElements(block, blockName, blockId);
            }
        }), { signal: signal });
    });
    var cards = block.querySelectorAll('.shadow-card.cursor-pointer');
    cards.length && cards.forEach(function (card) {
        var containedLink = card.querySelector('a[href]') || {};
        card.addEventListener('click', function (event) {
            var _a, _b;
            if (hasParentOfType(event.target, ['button', 'a'])) {
                return;
            }
            var ariaLabel = getAriaLabel(event.target);
            var clickText = ((_a = event.target) === null || _a === void 0 ? void 0 : _a.innerText) || ariaLabel;
            var isImageOrIcon = !clickText && checkImageLink(event.target);
            if (isImageOrIcon) {
                clickText = isImageOrIcon;
            }
            triggerElementClickEvent({
                blockId: blockId,
                blockName: blockName,
                clickType: "card",
                eventName: outBoundOrInBoundLink(containedLink.href),
                elementId: (_b = event.target) === null || _b === void 0 ? void 0 : _b.id,
                click_text: clickText,
                destination_url: (containedLink === null || containedLink === void 0 ? void 0 : containedLink.href) || window.location.href,
                ariaLabel: ariaLabel
            });
        }, { signal: signal });
    });
    var images = block.querySelectorAll('img.cursor-pointer');
    images.length && images.forEach(function (image) {
        image.addEventListener('click', function (event) {
            var _a;
            var ariaLabel = getAriaLabel(image);
            var clickText = (image === null || image === void 0 ? void 0 : image.alt) || ariaLabel || "image";
            setupMenuBoxElements(blockName, blockId);
            triggerElementClickEvent({
                blockId: blockId,
                blockName: blockName,
                clickType: "image",
                eventName: outBoundOrInBoundLink(),
                elementId: (_a = event.target) === null || _a === void 0 ? void 0 : _a.id,
                click_text: clickText,
                destination_url: window.location.href,
                ariaLabel: ariaLabel
            });
        }, { signal: signal });
    });
};
// Outstanding Questions
// https://communityuat.worldvision.org/settings/account
// It appears that blocks do not exist with the account page. 
// Messages Pop Up - Triggered by the messages icon
// This isn't a pop-up menu like the profile menu.
// Notifications Pop Up - Triggered by the bell icon
// This isn't a pop-up menu like the profile menu.
// Home 
// Quick Links to Get You Started
// The click is not showing any click_text or destination_url
