var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { dataLayerPush, normalizeObject, queryWithRetry } from "../shared/utils";
var videoPlayers = [];
function cleanUpVideoPlayers(players) {
    (players === null || players === void 0 ? void 0 : players.length) && players.forEach(function (player) {
        player = null; // Destroy the player instance
    });
    return [];
}
export function initializeVideoTracking() {
    return __awaiter(this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            // Clean up any existing video players
            videoPlayers = cleanUpVideoPlayers(videoPlayers);
            queryWithRetry('iframe[src*="cdn.iframe.ly/api"]', {
                delay: 50,
                maxTimeoutLimit: 4000,
                retryLimit: 200,
                successCallback: function (videoIframes) {
                    return _initializeVideoTracking(videoIframes, videoPlayers);
                },
            });
            return [2 /*return*/];
        });
    });
}
function _initializeVideoTracking(videoIframes, videoPlayers) {
    return __awaiter(this, void 0, void 0, function () {
        function setupPlayerInstance(iframe) {
            try {
                var player_1 = new globalThis.playerjs.Player(iframe);
                videoPlayers.push(player_1);
                player_1.on('ready', function () {
                    addPlayerTracking(player_1, iframe.src);
                });
            }
            catch (err) {
                console.error(err);
            }
        }
        function addPlayerTracking(player, iframeSrc) {
            var detectVideoType = function (url) {
                if (url.includes('vimeo')) {
                    return 'vimeo';
                }
                else if (url.includes('youtube') || url.includes('youtu.be')) {
                    return 'youtube';
                }
                else {
                    return 'html5';
                }
            };
            var videoMetaData = {
                title: player.title || window.document.title,
                url: player.url || iframeSrc || window.location.href,
                playerType: detectVideoType(iframeSrc)
            };
            // Track Video Percentage
            var configPercentages = [10, 25, 50, 75, 95];
            var percentageVals = configPercentages.map(function (val) { return val / 100; });
            var percentageTracked = {};
            player.on('play', function (data) {
                if (data && typeof data.seconds !== "undefined" && data.seconds !== null) {
                    trackPlayAction(__assign(__assign({}, data), { metaData: videoMetaData, action: 'play' }));
                }
            });
            player.on('pause', function (data) {
                trackPauseAction(__assign(__assign({}, data), { metaData: videoMetaData, action: 'pause' }));
            });
            player.on('ended', function (data) {
                trackEndAction(__assign(__assign({}, data), { metaData: videoMetaData, action: 'ended' }));
            });
            player.on('timeupdate', function (data) {
                var percent = (data !== null && data !== void 0 ? data : {}).percent;
                for (var _i = 0, percentageVals_1 = percentageVals; _i < percentageVals_1.length; _i++) {
                    var val = percentageVals_1[_i];
                    if (percent && percent >= val && !percentageTracked[val]) {
                        percentageTracked[val] = true;
                        var percentIndex = percentageVals.indexOf(val);
                        var progressVal = "".concat(configPercentages[percentIndex], "% Progress");
                        trackProgressAction(__assign(__assign({}, data), { metaData: videoMetaData, action: 'progress', progressVal: progressVal }));
                    }
                }
            });
        }
        function trackPlayAction(videoData) {
            var duration = videoData.duration;
            var _a = videoData.metaData, title = _a.title, playerType = _a.playerType, videoUrl = _a.url;
            var video_action = "play";
            dataLayerPush(normalizeObject({
                event: 'video_interactions',
                video_provider: playerType,
                video_title: title,
                event_action: video_action,
                event_category: "video_interactions",
                event_label: title,
                standard_interactions: 1,
                std_video_start: 1,
                video_action: video_action,
                video_length: duration,
                video_name: title,
                video_player: playerType,
                video_url: videoUrl,
            }));
        }
        function trackPauseAction(videoData) {
            var duration = videoData.duration;
            var _a = videoData.metaData, title = _a.title, playerType = _a.playerType, videoUrl = _a.url;
            var video_action = "pause";
            dataLayerPush(normalizeObject({
                event: 'video_interactions',
                video_provider: playerType,
                video_title: title,
                event_action: video_action,
                event_category: "video_interactions",
                event_label: title,
                standard_interactions: 1,
                std_video_start: 1,
                video_action: video_action,
                video_length: duration,
                video_name: title,
                video_player: playerType,
                video_url: videoUrl,
            }));
        }
        function trackProgressAction(videoData) {
            var duration = videoData.duration, progressVal = videoData.progressVal;
            var _a = videoData.metaData, title = _a.title, playerType = _a.playerType, videoUrl = _a.url;
            var video_action = progressVal !== null && progressVal !== void 0 ? progressVal : '';
            if (!video_action) {
                return;
            }
            dataLayerPush(normalizeObject({
                event: 'video_interactions',
                video_provider: playerType,
                video_action: video_action,
                video_title: title,
                event_action: video_action,
                event_category: "video_interactions",
                event_label: title,
                standard_interactions: 1,
                std_video_start: 1,
                video_length: duration,
                video_name: title,
                video_player: playerType,
                video_url: videoUrl,
            }));
        }
        function trackEndAction(videoData) {
            var duration = videoData.duration;
            var _a = videoData.metaData, title = _a.title, playerType = _a.playerType, videoUrl = _a.url;
            var video_action = "Watch to End";
            dataLayerPush(normalizeObject({
                event: 'video_interactions',
                video_provider: playerType,
                video_title: title,
                event_action: video_action,
                event_category: "video_interactions",
                event_label: title,
                standard_interactions: 1,
                std_video_start: 1,
                video_action: video_action,
                video_length: duration,
                video_name: title,
                video_player: playerType,
                video_url: videoUrl,
            }));
        }
        var _a;
        return __generator(this, function (_b) {
            if (!videoIframes || !videoIframes.length || !((_a = globalThis === null || globalThis === void 0 ? void 0 : globalThis.playerjs) === null || _a === void 0 ? void 0 : _a.Player)) {
                return [2 /*return*/];
            }
            videoIframes.forEach(function (iframe) { return setupPlayerInstance(iframe); });
            return [2 /*return*/];
        });
    });
}
