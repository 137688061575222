export function queryWithRetry(selector, _a) {
    var _b = _a.delay, delay = _b === void 0 ? 0 : _b, _c = _a.maxTimeoutLimit, maxTimeoutLimit = _c === void 0 ? 200 : _c, _d = _a.retryLimit, retryLimit = _d === void 0 ? 100 : _d, _e = _a.successCallback, successCallback = _e === void 0 ? null : _e, _f = _a.failureCallback, failureCallback = _f === void 0 ? null : _f, _g = _a.validate, validate = _g === void 0 ? null : _g;
    var retries = 0;
    var totalTime = 0;
    var validateElements = function (elements) {
        if (validate) {
            return validate(elements);
        }
        return elements.length > 0;
    };
    var attemptQuery = function () {
        var elements = document.querySelectorAll(selector);
        if (validateElements(elements)) {
            if (successCallback) {
                successCallback(elements);
            }
        }
        else {
            retries++;
            totalTime += delay;
            if (retries >= retryLimit || totalTime >= maxTimeoutLimit) {
                if (failureCallback) {
                    failureCallback();
                }
            }
            else {
                setTimeout(attemptQuery, delay);
            }
        }
    };
    // Start the first query attempt after the initial delay
    setTimeout(attemptQuery, delay);
}
export default queryWithRetry;
