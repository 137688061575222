var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { dataLayerPush, normalizeObject, getEhashCookie, getEhashFromPartAuthData, setOptParam } from "./utils";
var trackSurveySparrowComplete = function (event) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, response, customParams, surveyDetails, surveyName, surveyId, supporter_id, responsesStr, ehash, _b, ehashParam, eventData;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                _a = event && event.detail, response = _a.response, customParams = _a.customParams, surveyDetails = _a.surveyDetails;
                // Check If Response Is Empty
                if (!(response === null || response === void 0 ? void 0 : response.length) || !customParams || !surveyDetails) {
                    return [2 /*return*/];
                }
                surveyName = surveyDetails.name;
                surveyId = surveyDetails.id;
                supporter_id = customParams.supporter_id;
                responsesStr = response.reduce(function (responses, response, index) {
                    var answer = (function (value) {
                        var _a;
                        if (Array.isArray(value) && ((_a = value === null || value === void 0 ? void 0 : value[0]) === null || _a === void 0 ? void 0 : _a.value)) {
                            return "".concat(value[0].value);
                        }
                        else if (typeof response.answer === "string" || typeof response.answer === "number") {
                            return "".concat(response.answer);
                        }
                        else {
                            return "";
                        }
                    })(response.answer);
                    if (response.answer) {
                        responses.push(['question' + (index + 1), answer.replace(/[\,\:]/g, "")].join(':'));
                    }
                    return responses;
                }, []).join(',');
                _b = getEhashCookie();
                if (_b) return [3 /*break*/, 2];
                return [4 /*yield*/, getEhashFromPartAuthData()];
            case 1:
                _b = (_c.sent());
                _c.label = 2;
            case 2:
                ehash = _b;
                ehashParam = ehash ? { ehash: ehash } : {};
                eventData = normalizeObject(__assign({ "event": "survey_response", "event_action": "survey_response", "event_category": "survey", "event_label": [surveyId, responsesStr].join(':'), "survey_name": surveyName, "survey_questions_responses": responsesStr, "supporter_id": supporter_id }, (setOptParam("ehash", ehashParam))));
                // Push Data To DataLayer
                dataLayerPush(eventData);
                return [2 /*return*/];
        }
    });
}); };
(function (global) {
    global.document.addEventListener('track_survey_sparrow_complete', trackSurveySparrowComplete);
})(window);
(function (global) {
    /*
     * Listen For Survey Completed Message Event, then fire the custom event with the data.
     */
    var checkSurveyCompleted = function (e) {
        if (e && e.data && e.data.type && e.data.type === 'surveyCompleted') {
            var event_1 = new CustomEvent('track_survey_sparrow_complete', { detail: e.data });
            window.document.dispatchEvent(event_1);
            // Remove Message Handler
            global.removeEventListener('message', checkSurveyCompleted);
        }
    };
    global.addEventListener('message', checkSurveyCompleted);
})(window);
