var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { delay } from "../shared/utils";
export var isCommunitySiteUrl = /^https:\/\/community(uat)?\.worldvision\.org$/i.test(window.location.origin);
export var getQueryPayload = function (queryKey, variables) {
    var _a;
    if (variables === void 0) { variables = {}; }
    var gqlQueries = {
        spaces: "query {\n      spaces(limit: 100) {\n        nodes {\n          id,\n          name,\n          slug,\n          url,\n          relativeUrl,\n          network {\n            id,\n            name\n          }\n        }\n      }\n    }",
        systemSpaces: "query SystemSpaces {\n      systemSpaces {\n        id,\n        name,\n        slug,\n        url,\n        relativeUrl,\n        network {\n          id,\n          name\n        }\n      }\n    }",
        spaceByPath: "query spaceByPath($path: String) {\n      space(path: $path) {\n        name,\n        url,\n        id,\n        slug,\n      }\n    }",
        postById: "query post ($id: ID!) {\n      post(id: $id) {\n        id\n        title\n        slug\n        postType {\n          id\n          name\n        }\n      }\n    }",
        pageByPath: "query page($path: String!) {\n        page(path: $path) {\n          entity {\n            __typename\n            ... on Post {\n              id\n              title\n              slug\n              postType {\n                id\n                name\n              }\n            }\n          }\n          seoDetail {\n            title\n          }\n          space {\n            id\n            name\n            slug\n            relativeUrl\t\n          }\n        }\n    }",
        memberById: "query memberById($memberId:ID) {\n      member(\n        id: $memberId\n      ) {\n          email\n          id\n          externalId\n          name\n          role {\n            name\n            description\n          }\n          fields {\n              key\n              value\n          }\n        }\n      }\n    "
    };
    return {
        query: (_a = gqlQueries[queryKey]) !== null && _a !== void 0 ? _a : '',
        variables: variables
    };
};
export var requestBetterModeApi = function (queryPayload_1) {
    var args_1 = [];
    for (var _i = 1; _i < arguments.length; _i++) {
        args_1[_i - 1] = arguments[_i];
    }
    return __awaiter(void 0, __spreadArray([queryPayload_1], args_1, true), void 0, function (queryPayload, jsonParser) {
        var query, variables;
        if (jsonParser === void 0) { jsonParser = function (jsonData) { return jsonData; }; }
        return __generator(this, function (_a) {
            query = queryPayload.query, variables = queryPayload.variables;
            return [2 /*return*/, fetch("".concat(globalThis.BM.client.spaces.client.url), {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': "Bearer ".concat(globalThis.BM.client.spaces.client.accessToken), // Include your access token here if required
                    },
                    body: JSON.stringify({
                        query: query,
                        variables: variables // Include variables if your query/mutation requires them
                    })
                })
                    .then(function (resp) {
                    if (!resp.ok) {
                        throw new Error("Failed to fetch request: ".concat(resp.status));
                    }
                    return resp.json();
                })
                    .then(jsonParser)
                    .catch(function (err) {
                    console.error(err);
                    return null;
                })];
        });
    });
};
export var requestBettermodeSpaces = function () { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        return [2 /*return*/, requestBetterModeApi(getQueryPayload('spaces'), function (respData) { var _a, _b; return ((_b = (_a = respData === null || respData === void 0 ? void 0 : respData.data) === null || _a === void 0 ? void 0 : _a.spaces) === null || _b === void 0 ? void 0 : _b.nodes) || null; })];
    });
}); };
export var getBettermodeCommunityData = function (pageTypeData_1) {
    var args_1 = [];
    for (var _i = 1; _i < arguments.length; _i++) {
        args_1[_i - 1] = arguments[_i];
    }
    return __awaiter(void 0, __spreadArray([pageTypeData_1], args_1, true), void 0, function (pageTypeData, retry) {
        var maxRetry, hasCache, pathname, pageType, postId, systemSpace, isPost, spaces, _a, systemSpaces, _b, network, spacePath, currentSystemSpace, currentSpace, pageData, currentPost, _c;
        var _d, _e, _f, _g, _h;
        if (retry === void 0) { retry = 1; }
        return __generator(this, function (_j) {
            switch (_j.label) {
                case 0:
                    maxRetry = 10 * 10;
                    if (!isCommunitySiteUrl) {
                        return [2 /*return*/, null];
                    }
                    if (retry > maxRetry) {
                        return [2 /*return*/, null];
                    }
                    if (!!((_g = (_f = (_e = (_d = globalThis === null || globalThis === void 0 ? void 0 : globalThis.BM) === null || _d === void 0 ? void 0 : _d.client) === null || _e === void 0 ? void 0 : _e.spaces) === null || _f === void 0 ? void 0 : _f.client) === null || _g === void 0 ? void 0 : _g.accessToken)) return [3 /*break*/, 2];
                    return [4 /*yield*/, delay(100)];
                case 1:
                    _j.sent();
                    return [2 /*return*/, getBettermodeCommunityData(pageTypeData, ++retry)];
                case 2:
                    hasCache = Object.keys(globalThis.bettermodeCommunityDataCache).length;
                    pathname = window.location.pathname === '/' ? '/home' : window.location.pathname;
                    pageType = pageTypeData.pageType, postId = pageTypeData.postId, systemSpace = pageTypeData.systemSpace;
                    isPost = !!postId;
                    if (!(hasCache && Object.keys(globalThis.bettermodeCommunityDataCache.spaces).length)) return [3 /*break*/, 3];
                    _a = globalThis.bettermodeCommunityDataCache.spaces;
                    return [3 /*break*/, 5];
                case 3: return [4 /*yield*/, requestBettermodeSpaces()];
                case 4:
                    _a = _j.sent();
                    _j.label = 5;
                case 5:
                    spaces = _a;
                    if (!(hasCache && Object.keys(globalThis.bettermodeCommunityDataCache.systemSpaces).length)) return [3 /*break*/, 6];
                    _b = globalThis.bettermodeCommunityDataCache.systemSpaces;
                    return [3 /*break*/, 8];
                case 6: return [4 /*yield*/, requestBetterModeApi(getQueryPayload('systemSpaces'), function (respData) { var _a; return (_a = respData === null || respData === void 0 ? void 0 : respData.data) === null || _a === void 0 ? void 0 : _a.systemSpaces; })];
                case 7:
                    _b = _j.sent();
                    _j.label = 8;
                case 8:
                    systemSpaces = _b;
                    network = ((_h = spaces === null || spaces === void 0 ? void 0 : spaces[0]) === null || _h === void 0 ? void 0 : _h.network) || null;
                    spacePath = (/^(\/[^\/]+)\/?.*$/.exec(pathname) || ['', ''])[1];
                    currentSystemSpace = systemSpace && (systemSpace === null || systemSpace === void 0 ? void 0 : systemSpace.length) && systemSpaces.find(function (space) { return space.relativeUrl === "".concat(spacePath); }) || null;
                    currentSpace = !systemSpace && (spaces === null || spaces === void 0 ? void 0 : spaces.length) && spaces.find(function (space) { return space.relativeUrl === "".concat(spacePath); }) || null;
                    return [4 /*yield*/, requestBetterModeApi(getQueryPayload('pageByPath', { path: pathname }), function (respData) { var _a; return (_a = respData === null || respData === void 0 ? void 0 : respData.data) === null || _a === void 0 ? void 0 : _a.page; })];
                case 9:
                    pageData = _j.sent();
                    if (!isPost) return [3 /*break*/, 11];
                    return [4 /*yield*/, requestBetterModeApi(getQueryPayload('postById', { id: postId }), function (respData) { var _a; return (_a = respData === null || respData === void 0 ? void 0 : respData.data) === null || _a === void 0 ? void 0 : _a.post; })];
                case 10:
                    _c = _j.sent();
                    return [3 /*break*/, 12];
                case 11:
                    _c = {};
                    _j.label = 12;
                case 12:
                    currentPost = _c;
                    globalThis.bettermodeCommunityDataCache = {
                        systemSpaces: systemSpaces,
                        spaces: spaces,
                        network: network
                    };
                    return [2 /*return*/, __assign(__assign({}, globalThis.bettermodeCommunityDataCache), { currentSpace: currentSpace, currentSystemSpace: currentSystemSpace, currentPost: currentPost, currentPage: pageData })];
            }
        });
    });
};
export var getBettermodeExtraMemberData = function (memberId) { return __awaiter(void 0, void 0, void 0, function () {
    var memberData, err_1;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, requestBetterModeApi(getQueryPayload('memberById', { memberId: memberId }), function (respData) { var _a; return (_a = respData === null || respData === void 0 ? void 0 : respData.data) === null || _a === void 0 ? void 0 : _a.member; })];
            case 1:
                memberData = _a.sent();
                return [2 /*return*/, memberData];
            case 2:
                err_1 = _a.sent();
                console.error(err_1);
                return [2 /*return*/, null];
            case 3: return [2 /*return*/];
        }
    });
}); };
