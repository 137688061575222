var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import queryWithRetry from "./queryWithRetry";
var normalizeAndEncodeString = function (input) {
    return (input + "")
        .trim()
        .replaceAll(" ", "_")
        .replaceAll("%20", "_")
        .replaceAll("&amp;", "&")
        .replaceAll("&lt;", "<")
        .replaceAll("&gt;", ">")
        .replaceAll("&quot;", "'")
        .toLowerCase();
};
var keysToSkipNormalize = ["currency"];
var normalizeObject = function (inputObj) {
    var normalizedObject = inputObj.constructor === Object ? {} : [];
    Object.keys(inputObj).length &&
        (inputObj.constructor === Object || inputObj.constructor === Array) &&
        Object.keys(inputObj).forEach(function (key) {
            var _a, _b, _c;
            if (((_a = inputObj[key]) === null || _a === void 0 ? void 0 : _a.constructor) === String && !keysToSkipNormalize.includes(key)) {
                normalizedObject[key] = normalizeAndEncodeString(inputObj[key]);
            }
            else if (((_b = inputObj[key]) === null || _b === void 0 ? void 0 : _b.constructor) === Object ||
                ((_c = inputObj[key]) === null || _c === void 0 ? void 0 : _c.constructor) === Array) {
                if (inputObj.constructor === Object) {
                    normalizedObject[key] = normalizeObject(inputObj[key]);
                }
                else {
                    Array.isArray(normalizedObject) &&
                        normalizedObject.push(normalizeObject(inputObj[key]));
                }
            }
            else {
                normalizedObject[key] = inputObj[key];
            }
        });
    return normalizedObject;
};
var dataLayerPush = function (dataLayerObject) {
    window["dataLayer"] = window["dataLayer"] || [];
    window["dataLayer"].push(dataLayerObject);
};
var dataLayerPushEcommerce = function (dataLayerObject) {
    // It's recommended to null out the ecommerce object before dataLayer push
    // https://developers.google.com/analytics/devguides/collection/ua/gtm/enhanced-ecommerce#clear-ecommerce
    dataLayerPush({ ecommerce: null });
    dataLayerPush(dataLayerObject);
};
function sha256(message) {
    return __awaiter(this, void 0, void 0, function () {
        var msgBuffer, hashBuffer, hashArray, hashHex;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    msgBuffer = new TextEncoder().encode(message);
                    return [4 /*yield*/, crypto.subtle.digest("SHA-256", msgBuffer)];
                case 1:
                    hashBuffer = _a.sent();
                    hashArray = Array.from(new Uint8Array(hashBuffer));
                    hashHex = hashArray.map(function (b) { return b.toString(16).padStart(2, "0"); }).join("");
                    return [2 /*return*/, hashHex];
            }
        });
    });
}
function toEhashAsync(email_1) {
    return __awaiter(this, arguments, void 0, function (email, backupVal) {
        if (backupVal === void 0) { backupVal = ""; }
        return __generator(this, function (_a) {
            if (!email || typeof email !== "string") {
                return [2 /*return*/, Promise.resolve("")];
            }
            return [2 /*return*/, sha256(email.toLowerCase()).catch(function () { return backupVal; })];
        });
    });
}
export function delay(ms) {
    return new Promise(function (resolve) {
        setTimeout(resolve, ms);
    });
}
export function getEhashCookie() {
    return getCookieVal("SESSehash");
}
export function getSupporterID() {
    var partAuth = getCookieVal("SESSpartauth") ? JSON.parse(getCookieVal("SESSpartauth")) : "";
    return (partAuth === null || partAuth === void 0 ? void 0 : partAuth.supporter_number) || "";
}
export function getPartAuthCookieData(key) {
    if (key === void 0) { key = null; }
    var partAuthVal = getCookieVal("SESSpartauth");
    if (partAuthVal) {
        var partAuthData = parseJson(partAuthVal, {});
        return key ? partAuthData[key] : partAuthData;
    }
    else {
        return;
    }
}
function getEhashFromPartAuthData() {
    return __awaiter(this, void 0, void 0, function () {
        var partAuthEmail, email, ehash;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    partAuthEmail = getPartAuthCookieData("email");
                    email = partAuthEmail || "";
                    return [4 /*yield*/, toEhashAsync(email)];
                case 1:
                    ehash = _a.sent();
                    return [2 /*return*/, ehash];
            }
        });
    });
}
export function parseJson(str, defaultValue, debug) {
    if (defaultValue === void 0) { defaultValue = null; }
    if (debug === void 0) { debug = false; }
    try {
        if (typeof str !== "string") {
            throw new Error("String type required");
        }
        return JSON.parse(str);
    }
    catch (err) {
        if (debug) {
            console.error(err);
        }
        return defaultValue;
    }
}
export function setCookie(name, value, options) {
    if (options === void 0) { options = {}; }
    var expires = options.expires, _a = options.domain, domain = _a === void 0 ? ".worldvision.org" : _a, _b = options.path, path = _b === void 0 ? '/' : _b, _c = options.secure, secure = _c === void 0 ? false : _c, sameSite = options.sameSite, _d = options.httpOnly, httpOnly = _d === void 0 ? false : _d;
    var cookieValue = value;
    // Check if the value is an object, stringify and URI encode it
    if (typeof value === 'object' && value !== null) {
        try {
            cookieValue = encodeURIComponent(JSON.stringify(value));
        }
        catch (error) {
            console.error('Error while parsing JSON data:', error);
        }
    }
    var cookieString = "".concat(name, "=").concat(cookieValue);
    if (expires instanceof Date) {
        cookieString += "; expires=".concat(expires.toUTCString());
    }
    else if (typeof expires === 'string') {
        var expiresDate = new Date(expires);
        cookieString += "; expires=".concat(expiresDate.toUTCString());
    }
    if (domain) {
        cookieString += "; domain=".concat(domain);
    }
    if (path) {
        cookieString += "; path=".concat(path);
    }
    if (secure) {
        cookieString += "; secure";
    }
    if (sameSite) {
        cookieString += "; samesite=".concat(sameSite);
    }
    if (httpOnly) {
        cookieString += "; HttpOnly";
    }
    document.cookie = cookieString;
}
export function getCookieVal(name, decodeVal) {
    if (decodeVal === void 0) { decodeVal = true; }
    if (!window.document.cookie) {
        return "";
    }
    var reCookieName = new RegExp("".concat(name, "=([^;]+)")).exec(window.document.cookie);
    var cookieVal = reCookieName && reCookieName[1] || "";
    if (decodeVal === true) {
        return decodeURIComponent(cookieVal);
    }
    return cookieVal;
}
export var isValueSetInDatalayer = function (key) {
    var dl = window["dataLayer"] || [];
    return (dl === null || dl === void 0 ? void 0 : dl.length) && dl.some(function (eventData) { return eventData === null || eventData === void 0 ? void 0 : eventData[key]; }) || null;
};
export var SPONSORSHIP_PRODUCT_SKU = "S1000001";
export var CHOSEN_PRODUCT_SKU = "S1000002";
var isChosenProduct = function (productId) {
    return productId === CHOSEN_PRODUCT_SKU;
};
export var SPONSORSHIP_ITEM_ID = "380";
var isSponsorshipProduct = function (productItemId) {
    return productItemId === SPONSORSHIP_ITEM_ID;
};
export var COVER_THE_FEE_ID = "D10000225";
var isCoverTheFeeProduct = function (productItemSku) {
    return productItemSku === COVER_THE_FEE_ID;
};
var setOptParam = function (key, obj, defaultVal) {
    var _a, _b;
    if (defaultVal === void 0) { defaultVal = undefined; }
    if (key && obj[key]) {
        return _a = {}, _a[key] = obj[key], _a;
    }
    else if (defaultVal) {
        return _b = {}, _b[key] = defaultVal, _b;
    }
    else {
        return {};
    }
};
/*
* Get the subdomain of the current worldvision.org domain
*/
var getWVSubdomain = function () { return (/(.+)\.worldvision.org$/.exec(window.location.hostname) || ["", ""])[1]; };
/*
* Check if the email is a worldvision.org email
*/
var isWvEmail = function (email) {
    return /@worldvision.org$/i.test(email);
};
/*
* Convert a function to a callback function
*/
var asCallback = function (callback, delay) {
    if (delay === void 0) { delay = 0; }
    return function () {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        setTimeout(function () { return callback.apply(void 0, args); }, delay);
    };
};
export function getSupporterIdFromBlueConic(defaultVal) {
    var _a, _b, _c;
    if (defaultVal === void 0) { defaultVal = ""; }
    // Check If BlueConic is available and the profile is available
    if ((globalThis === null || globalThis === void 0 ? void 0 : globalThis.blueConicClient) &&
        (globalThis === null || globalThis === void 0 ? void 0 : globalThis.blueConicClient.profile)) {
        var supporter_id = (_c = (_b = (_a = globalThis === null || globalThis === void 0 ? void 0 : globalThis.blueConicClient) === null || _a === void 0 ? void 0 : _a.profile) === null || _b === void 0 ? void 0 : _b.getProfile()) === null || _c === void 0 ? void 0 : _c.getValue("WV_Account");
        return supporter_id !== null && supporter_id !== void 0 ? supporter_id : defaultVal;
    }
    return defaultVal;
}
export { normalizeAndEncodeString, dataLayerPush, normalizeObject, sha256, toEhashAsync, isChosenProduct, isSponsorshipProduct, isCoverTheFeeProduct, dataLayerPushEcommerce, setOptParam, getEhashFromPartAuthData, getWVSubdomain, isWvEmail, asCallback, queryWithRetry };
