import { isWvEmail, getPartAuthCookieData, getSupporterIdFromBlueConic } from "../shared/utils";
export function abortThenGetNewController(controller) {
    if (controller && controller.signal && !controller.signal.aborted) {
        // This is 
        controller.abort();
        controller = null;
    }
    return new AbortController();
}
export var isStaffMember = function (member) {
    return isWvEmail(member.email) || (member.teamamte === true || member.teamamte === 'true');
};
export var getSupporterId = function (memberExtraData) {
    return getPartAuthCookieData('supporter_number')
        || getSupporterIdFromBlueConic('')
        || getSupporterIdByMemberExtraData(memberExtraData, ''); // We tried our best to get the supporter_id
};
export var getSupporterIdByMemberExtraData = function (memberExtraData, defaultVal) {
    if (defaultVal === void 0) { defaultVal = ""; }
    var supporter_id = getMemberFieldData(memberExtraData, 'supporter_id', defaultVal);
    if (!["string", "number"].includes(typeof supporter_id) || !/^\d{2,}$/.test("".concat(supporter_id))) {
        return defaultVal;
    }
    return supporter_id;
};
export var getMemberFieldData = function (memberExtraData, fieldKey, defaultVal) {
    if (Array.isArray(memberExtraData === null || memberExtraData === void 0 ? void 0 : memberExtraData.fields) && memberExtraData.fields.length) {
        for (var _i = 0, _a = memberExtraData.fields; _i < _a.length; _i++) {
            var field = _a[_i];
            if ((field === null || field === void 0 ? void 0 : field.key) === fieldKey) {
                var keyValue = safeJsonValue(field === null || field === void 0 ? void 0 : field.value, field === null || field === void 0 ? void 0 : field.value);
                if (Array.isArray(keyValue)) {
                    return keyValue.join(",");
                }
                return keyValue !== null && keyValue !== void 0 ? keyValue : defaultVal;
            }
        }
    }
};
export var safeJsonValue = function (jsonString, defaultVal) {
    if (typeof jsonString !== 'string') {
        return defaultVal;
    }
    try {
        return JSON.parse(jsonString);
    }
    catch (e) {
        return defaultVal;
    }
};
